import Loader from "react-loader-spinner";
import React from "react";

const apiConfig = {
    // baseUrl: 'https://asp-api.ithit.ro/public/api',
    baseUrl: 'https://industrial-api.ithit.ro/public/api',
    // baseUrl: 'http://localhost:8000/api',
    s1Url: 'https://expinst.oncloud.gr/s1services',
    // s1Url: 'https://aspgroup.oncloud.gr/s1services',
    header: {
        'Content-type': 'application/json',
        //'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjoiNWZiOTA4M2I2YWVjYWMzOTkwYmIwY2VlIiwiZW1haWwiOiJ0ZXN0QHRlc3QuY29tIn0sImlhdCI6MTYwNTk4Nzg3OSwiZXhwIjoxNjA2MTYwNjc5fQ.3Aln_CBKpTcfM0CRj6XdC-ppiM6YOzbEsL9yDwB57_s'
    },
    loader: <Loader
        type="Puff"
        color="#00BFFF"
        height={50}
        width={50}
        timeout={30000}
        style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}
    />
};

export default apiConfig;
