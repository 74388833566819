/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import axios from "axios";
import apiConfig from "../../apiConfig";
import Loader from "react-loader-spinner";
import Cookies from 'js-cookie';
import LoadOrders from "./LoadOrders";

class OrderDetailsGestionar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cars: [],
            data: '',
            document: '',
            filiala: '',
            cod: '',
            companie: '',
            valoare: '',
            moneda: '',
            anulat: 0,
            observatii: '',
            orderArticles: [],
            orderBarcodes: [],
            showModal: false,
            scannedBarcodeDetails: {
                id: -1,
                nume_articol: "",
                cantitate: 0,
                unitate_masura1: "",
                unitate_masura2: "",
                barcode: "",
            },
            selectedCarId: -1,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBarCodeChange = this.handleBarCodeChange.bind(this);
        this.validateScannedQuantity = this.validateScannedQuantity.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleMailSubmit = this.handleMailSubmit.bind(this);

        this.handleCarChange = this.handleCarChange.bind(this);
    }

    handleCarChange(event) {
        event.preventDefault();
        this.setState({
            selectedCarId: event.target.value
        })
    }

    componentDidMount() {
        let requestBody = {
            filters: ""
        }
        if (this.props.orderId) {
            this.setState({
                isLoading: true
            })
            axios.post(`${apiConfig.baseUrl}/orders/details/${this.props.orderId}`, requestBody).then((response) => {
                let responseData = response.data;

                if (responseData.success) {
                    this.setOrderFields(responseData.orderDetails, responseData.orderItems)
                } else {
                    alert(responseData.error);
                }
            });

            axios.get(`${apiConfig.baseUrl}/cars`).then((response) => {
                let responseData = response.data;

                if (responseData.success) {
                    this.setState({cars: responseData.cars, isLoading: false});
                } else {
                    alert(responseData.error);
                }
            });

            axios.get(`${apiConfig.baseUrl}/orders/getOrderBarcodes/${this.props.orderId}`)
                .then((response) => {
                    let responseData = response.data;

                    if (responseData.success) {
                        console.warn(responseData.barcodes)
                        this.setState({orderBarcodes: responseData.barcodes});
                    } else {
                        alert(responseData.error);
                    }
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setOrderFields(orderDetails, orderItems) {
        let formattedOrderArticles = [];

        for (let index in orderItems) {
            let article = orderItems[index];
            article.linenum = parseInt(index) + 1;

            formattedOrderArticles.push(article);
            console.log(article);
        }
        console.log('OrderArticles', formattedOrderArticles)

        this.setState({
            data: orderDetails.data,
            document: orderDetails.document,
            filiala: orderDetails.filiala,
            cod: orderDetails.cod,
            companie: orderDetails.nume,
            valoare: orderDetails.valoare_totala,
            moneda: orderDetails.moneda,
            anulat: orderDetails.anulat,
            observatii: orderDetails.observatii,
            orderArticles: formattedOrderArticles,
            isLoading: false,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let foundProblems = this.validateScannedQuantity(event);
        let proceedConfirmBox;

        if (foundProblems == true) {
            proceedConfirmBox = confirm("Au fost gasite diferente in etapa de scanare. Doriti sa continuati cu trimiterea comenzii?");
        } else {
            proceedConfirmBox = confirm("Cantitatile sunt identice. Doriti sa continuati cu trimiterea comenzii?");
        }

        if (proceedConfirmBox) // Enter is pressed
        {
            let updatedFields = {
                "TRNDATE": this.state.data,
                "FINCODE": this.state.document,
                "TRDR": this.state.clientId,
                // "SUMAMNT": this.state.valoare,
                "SOCURRENCY": 123,
                "COMMENTS": this.state.observatii,
                // "SALESMAN": Cookies.get('softone_id'),
            };

            if (this.state.anulat.toLowerCase() === 'nu') {
                updatedFields["ISCANCEL"] = 0;
            }
            if (this.state.selectedCarId != -1) {
                updatedFields["RSRC"] = this.state.selectedCarId;
            }

            if (this.props.scanType == 'pregatita') {
                updatedFields["CCCPrepared"] = 1;
            }

            if (this.props.scanType == 'incarcata') {
                updatedFields["CCCLoaded"] = 1;
            }

            if (this.props.scanType == 'descarcata') {
                updatedFields["CCCLivrat"] = 1;
            }


            let extralines = [];

            this.state.orderArticles.forEach(article => {
                let preparedQuantity = 0;
                let loadedQuantity = 0;
                let unloadedQuantity = 0;

                if (this.props.scanType == 'incarcata') {
                    loadedQuantity = parseInt(document.getElementById(`${article.articol}_prepared_quantity`).innerText);
                }
                else if (this.props.scanType == 'descarcata')   {
                    unloadedQuantity = parseInt(document.getElementById(`${article.articol}_prepared_quantity`).innerText);
                }
                else {
                    preparedQuantity = parseInt(document.getElementById(`${article.articol}_prepared_quantity`).innerText);
                }


                let articleLine = {
                    "LINENUM": article.linenum,
                    "MTRL": article.articol,
                    "PRICE": article.pret_ridicata,
                    "CCCQTYI": article.cantitate_initiala,
                };

                if (preparedQuantity)   {
                    articleLine["CCCQTYP"] = preparedQuantity;
                    articleLine["QTY1"] = preparedQuantity;
                    // "QTY1": article.cantitate,
                }
                if (loadedQuantity) {
                    articleLine["CCCQTYINC"] = loadedQuantity;
                    articleLine["QTY1"] = loadedQuantity;
                }
                if (unloadedQuantity)   {
                    articleLine["CCCQTYDESC"] = unloadedQuantity;
                }

                extralines.push(articleLine);
            });

            let url;

            if (this.props.orderId) {
                url = `${apiConfig.baseUrl}/orders/update?orderId=${this.props.orderId}`;
            } else {
                url = `${apiConfig.baseUrl}/orders/insert`;
            }

            let requestBody = {
                fields: updatedFields,
                extraLines: extralines,
            }

            console.log(requestBody);
            axios.post(url, requestBody,{headers: apiConfig.header}).then((response) => {
                let responseData = response.data;

                if (responseData.success) {
                    console.log(responseData);
                    alert('Comanda a fost salvata cu succes');
                    if (this.props.scanType == 'pregatita') {
                        window.location.href = '/admin/gestionar/control';
                    } else if (this.props.scanType == 'incarcata') {
                        window.location.href = '/admin/gestionar/load';
                    } else if (this.props.scanType == 'descarcata') {
                        window.location.href = '/admin/sofer/control';
                    } else {
                        window.location.href = '/admin/orders';
                    }
                } else {
                    alert(responseData.error);
                }
            });
        }
        else {
            document.querySelectorAll('.scanned_quantity').forEach((object) => {
                object.innerText = '0'
            })
        }
    }

    handleBarCodeChange(event) {
        event.preventDefault();

        if (event.target.value.length > 6) // ENTER IS PRESSED
        {
            let barcodeInput = event.target;
            let barcode = barcodeInput.value;
            let articleFound = 0;

            this.state.orderBarcodes
                .forEach((barcodeDetails) => {
                    if (barcode === barcodeDetails.CODBARE) {
                        articleFound = 1;
                        this.setState({scannedBarcodeDetails: barcodeDetails});

                        console.log(`${barcodeDetails.MTRL}_prepared_quantity`);

                        let preparedQuantityColumn = document.getElementById(`${barcodeDetails.MTRL}_prepared_quantity`);

                        if (preparedQuantityColumn) {
                            let preparedQuantityUntilNow = parseInt(preparedQuantityColumn.innerText) ?? 0;
                            preparedQuantityColumn.innerText = preparedQuantityUntilNow + parseInt(barcodeDetails.QTY1)
                        } else {
                            alert('Acest articol nu se afla pe comanda curenta');
                        }
                        barcodeInput.value = ''
                        barcodeInput.focus();
                    }
                })
            if (articleFound === 0) {
                alert('A intervenit o eroare. Va rugam verificati ca acest cod de bare este corect si se afla pe comanda!')
                barcodeInput.value = '';
                barcodeInput.focus();
            }
        }
    }

    validateScannedQuantity(event) {
        event.preventDefault();
        let foundProblems = false;

        this.state.orderArticles.forEach(article => {
            let preparedQuantity = parseInt(document.getElementById(`${article.articol}_prepared_quantity`).innerText)

            console.error(this.props.scanType.toLowerCase());
            console.error(article);
            if (this.props.scanType.toLowerCase() === 'incarcata') {
                if (article.cantitate_pregatita != preparedQuantity) {
                    alert(`Produsul ${article.nume} cu ID ${article.articol} nu este scanat corect. Cantitate pregatita: ${article.cantitate_pregatita} VS Cantitate scanata ${preparedQuantity}`);
                    foundProblems = true;
                    return;
                }
            }
            else {
                if (article.cantitate != preparedQuantity) {
                    alert(`Produsul ${article.nume} cu ID ${article.articol} nu este scanat corect. Cantitate comandata: ${article.cantitate} VS Cantitate scanata ${preparedQuantity}`);
                    foundProblems = true;
                    return;
                }
            }
        });

        if (foundProblems === false) {
            alert('Toate produsele au fost scanate corect');
        }

        return foundProblems;
    }


    toggleModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    handleMailSubmit(event) {
        event.preventDefault();

        let requestBody = {
            "receiver_name": this.state.companie,
            "receiver_email": this.state.orders[0].client_email,
            "subject": `[Express Instal] Comanda in curs de livrare`,
            "body": `Buna ziua, soferul nostru a plecat cu comanda XYZ. (va fi template HTML)`
        }

        console.log(requestBody);
        axios.post(`${apiConfig.baseUrl}/notification/mail/send`, requestBody).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                alert('Ati trimis cu succes mail-ul catre client!')
            } else {
                alert(responseData.error);
            }
        });
    }

    render() {
        let formContent;
        if (this.state.isLoading) {
            formContent = apiConfig.loader
        } else {
            formContent = <Form onSubmit={this.handleSubmit} onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
            }}>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="data">Data</label>
                            <Input type="text" id="data" defaultValue={this.state.data} onChange={(e) => {
                                this.setState({data: e.target.value});
                            }} disabled/>
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">

                        <FormGroup>
                            <label htmlFor="document">Document</label>
                            <Input type="text" id="document" defaultValue={this.state.document} onChange={(e) => {
                                this.setState({document: e.target.value});
                            }} disabled/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="pl-md-1" md="6">

                        <FormGroup>
                            <label htmlFor="document">Cod</label>
                            <Input type="text" id="cod" defaultValue={this.state.cod} onChange={(e) => {
                                this.setState({data: e.target.value});
                            }} disabled/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="12">
                        <FormGroup>
                            <label htmlFor="data">Nume companie</label>
                            <Input type="text" id="companie" defaultValue={this.state.companie} onChange={(e) => {
                                this.setState({data: e.target.value});
                            }} disabled/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="data">Valoare</label>
                            <Input type="text" id="valoare" defaultValue={this.state.valoare} onChange={(e) => {
                                this.setState({data: e.target.value});
                            }} disabled/>
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">

                        <FormGroup>
                            <label htmlFor="document">Moneda</label>
                            <Input type="text" id="moneda" defaultValue={this.state.moneda} onChange={(e) => {
                                this.setState({data: e.target.value});
                            }} disabled/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    {/*<Col className="pr-md-1" md="6">*/}
                    {/*    <FormGroup>*/}
                    {/*        <label htmlFor="data">Anulat</label>*/}
                    {/*        <Input type="text" id="anulat" defaultValue={this.state.anulat} onChange={(e) => {*/}
                    {/*            this.setState({data: e.target.value});*/}
                    {/*        }}/>*/}
                    {/*    </FormGroup>*/}
                    {/*</Col>*/}
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="document">Observatii</label>
                            <Input type="text" id="observatii" defaultValue={this.state.observatii} onChange={(e) => {
                                this.setState({data: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>

                <br/><br/>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="barcode">Cod de bare</label>
                            <Input autoFocus type="text" id="barcode" onBlur={this.handleBarCodeChange}/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="pr-md-1" md="12">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                            <tr>
                                <th>Id</th>
                                <th>Nume</th>
                                <th>Cod</th>
                                <th>Cantitate comandata</th>
                                {this.props.scanType.toLowerCase() === 'incarcata' ? <th>Cantitate pregatita</th> : ''}
                                <th>Cantitate {this.props.scanType}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.orderArticles.map(article => (
                                    <tr key={article.articol}>
                                        <td>{article.articol}</td>
                                        <td>{article.nume}</td>
                                        <td>{article.cod}</td>
                                        <td>{article.cantitate}</td>
                                        {this.props.scanType.toLowerCase() === 'incarcata' ? <td>{article.cantitate_pregatita}</td> : ''}
                                        <td id={`${article.articol}_prepared_quantity`} className={'scanned_quantity'}> 0</td>
                                    </tr>
                                ))
                            }
                            </tbody>

                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label style={{display: this.props.scanType == 'incarcata' ? 'block' : 'none'}}>Alege
                                masina</label>
                            <Input
                                type="select"
                                onChange={this.handleCarChange}
                                style={{display: this.props.scanType == 'incarcata' ? 'block' : 'none'}}
                            >
                                <option value=''> Selecteaza masina</option>
                                {
                                    this.state.cars.map(car => (
                                        <option value={car.id}>
                                            {car.name} - {car.nr_inmatriculare}
                                        </option>
                                    ))
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <Button className="btn-fill" color="primary" type="submit"
                                style={{display: Cookies.get('role') == 'gestionar' || Cookies.get('role') == 'sofer' ? 'block' : 'none'}}>
                            Validare scanare
                        </Button>
                    </Col>
                </Row>
            </Form>;
        }

        return (
            <Col md={this.props.colSize}>
                <Card>
                    <CardHeader>
                        <h5 className="title">Detalii comanda</h5>
                    </CardHeader>
                    <CardBody>
                        {formContent}
                    </CardBody>
                    <CardFooter>

                        <Button className="btn-fill" color="primary" type="submit"
                                onClick={this.handleMailSubmit}
                                style={{display: this.props.scanType == 'incarcata' ? 'block' : 'none'}}
                        >
                            Marfa incarcata? Notifica client
                        </Button>

                    </CardFooter>
                </Card>
            </Col>
        );
    }
}

export default OrderDetailsGestionar;
